import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import MuiDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { graphql, Link } from 'gatsby';
import Layout from '../layouts/Default';
import Section from '../components/Section';
import { BlogList } from '../components/BlogRoll/BlogRoll';
import AuthorInfo from '../components/AuthorInfo/AuthorInfo';

const Divider = styled(MuiDivider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const ArticlesHeader = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ArticlesBy = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  flexGrow: 1,
}));

const BrowseAll = styled('span')(({ theme }) => ({
  ...theme.typography.subtitle2,
}));

export default function AuthorRoute(props) {
  const {
    site, authorsYaml, allFile,
  } = props.data;
  const {
    name,
  } = authorsYaml;
  const { title } = site.siteMetadata;
  const articlesBy = `Articles by ${name.split(' ')[0]}`;
  const authorImage = allFile.edges[0].node.childImageSharp.gatsbyImageData;
  return (
    <Layout>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        })}
      >
        <Helmet title={`${name} | ${title}`} />
        <div>
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
            >
              <AuthorInfo
                author={{
                  ...authorsYaml,
                  image: authorImage,
                }}
                disableAuthorLinks
              />
              <Divider />
              <ArticlesHeader>
                <ArticlesBy>
                  {articlesBy}
                </ArticlesBy>
                <BrowseAll>
                  <Link to="/authors/">Browse all authors</Link>
                </BrowseAll>
              </ArticlesHeader>
              <BlogList data={props.data} />
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

AuthorRoute.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
    authorsYaml: PropTypes.object,
    allFile: PropTypes.object,
  }),
};

export const pageQuery = graphql`query PostsByAuthorId($authorId: String!, $authorIdRegex: String!) {
  site {
    siteMetadata {
      title
    }
  }
  allMdx(filter: {fields: {authorId: {eq: $authorId}}}) {
    edges {
      node {
        id
        frontmatter {
          title
          description
          templateKey
          date(formatString: "MMMM DD, YYYY")
          author {
            id
            name
            bio
            twitter
          }
          featuredpost
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        fields {
          authorId
          slug
        }
      }
    }
  }
  authorsYaml(id: {eq: $authorId}) {
    id
    name
    bio
    twitter
  }
  allFile(
    filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "authors"}, name: {regex: $authorIdRegex}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
            width: 200
            quality: 100
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`;
